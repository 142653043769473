import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/reusable/ScrollToTop";
import Router from "./components/Router";
import { Helmet } from "react-helmet";
function App() {
    return (
        <>
            <Helmet>
                <title>Samudera Marga Wisesa</title>
                <meta name="description" content="Mie Kering Terbaik Nusantara"/>
            </Helmet>
            <BrowserRouter>
                <ScrollToTop/>
                <Router />
            </BrowserRouter>
        </>

    );
}

export default App;
