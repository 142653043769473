import { render } from "@testing-library/react"
import { Col, Container, Image, Row } from "react-bootstrap"
import Palette from "../utils/Palette"
import MockupImage from "../../assets/image.png"
import history5 from "../../assets/mockup_history_img_5.png"
import history2 from "../../assets/mockup_history_img_2.png"
import history3 from "../../assets/mockup_history_img_3.png"
import history4 from "../../assets/mockup_history_img_4.png"
import { Icon } from "@iconify/react"
import CustomHeading from "../reusable/CustomHeading"
import Hero from "../reusable/Hero"
import { Link, useOutletContext } from "react-router-dom"

export default function CSRPage() {
	const [isMobile, setIsMobile, lang, setLang] = useOutletContext()

	const CSRRow = ({ data }) => {
		return (
			<Col md={3} sm={12}>
				<Link className="text-decoration-none" to={"/csr/" + data.index}>
					<div>
						<div
							//className="video-wrap"
							style={{}}
						>
							<img src={data.thumbnail} width={"100%"} />
						</div>
					</div>
					<div style={{ marginTop: isMobile ? "0.75rem" : "1.5rem" }}>
						<div
							style={{
								color: "#666",
								fontSize: "1rem",
								lineHeight: "1.5rem",
								marginBottom: isMobile ? "0.25rem" : "0.5rem"
							}}
						>
							{data.date}
						</div>
						<div
							style={{
								fontSize: "1rem",
								lineHeight: "1.5rem",
								marginBottom: "0.5rem",
								fontWeight: 600,
								color: "black"
							}}
						>
							{data.title}
						</div>
					</div>
				</Link>
			</Col>
		)
	}
	return (
		<>
			<Hero
				title={lang.CSRPage.hero.title}
				desc={lang.CSRPage.hero.description}
			/>
			{/*<Container style={{ marginBottom: isMobile ? "1rem" : "3rem" }}>*/}
			{/*	<Row*/}
			{/*		style={{*/}
			{/*			marginBottom: isMobile ? "1rem" : "3rem",*/}
			{/*			marginTop: isMobile ? "1rem" : "5rem",*/}
			{/*			rowGap: "1rem"*/}
			{/*		}}*/}
			{/*	>*/}
			{/*		<Col*/}
			{/*			lg={3}*/}
			{/*			md={6}*/}
			{/*			sm={12}*/}
			{/*			style={{*/}
			{/*				fontSize: "1.75rem",*/}
			{/*				fontWeight: 700,*/}
			{/*				lineHeight: "2.25rem"*/}
			{/*			}}*/}
			{/*		>*/}
			{/*			{lang.CSRPage.section1.title}*/}
			{/*		</Col>*/}
			{/*		<Col*/}
			{/*			style={{*/}
			{/*				fontSize: "1rem",*/}
			{/*				color: "#666",*/}
			{/*				lineHeight: "1.5rem"*/}
			{/*			}}*/}
			{/*		>*/}
			{/*			{lang.CSRPage.section1.description}*/}
			{/*		</Col>*/}
			{/*	</Row>*/}
			{/*	<Row>*/}
			{/*		<img src={require("../../assets/csr.png")} />*/}
			{/*	</Row>*/}
			{/*</Container>*/}
			{/*<Row>*/}
			{/*	<svg*/}
			{/*		xmlns="http://www.w3.org/2000/svg"*/}
			{/*		width="1440"*/}
			{/*		height="60"*/}
			{/*		viewBox="0 0 1440 60"*/}
			{/*		fill="none"*/}
			{/*		preserveAspectRatio="none"*/}
			{/*	>*/}
			{/*		<path*/}
			{/*			d="M0 30.9096C619 -9.8169 831.5 -10.7866 1440 30.9096V60H0V30.9096Z"*/}
			{/*			fill="#F7F7F8"*/}
			{/*		/>*/}
			{/*	</svg>*/}
			{/*</Row>*/}
			{/*<div*/}
			{/*	style={{*/}
			{/*		backgroundColor: "#F7F7F8",*/}
			{/*		paddingBottom: isMobile ? "1.5rem" : "5rem"*/}
			{/*	}}*/}
			{/*>*/}
			{/*	<Container>*/}
			{/*		<Container style={{ columnGap: "5rem" }}>*/}
			{/*			<Row*/}
			{/*				style={{*/}
			{/*					marginBottom: isMobile ? "1.5rem" : "5rem",*/}
			{/*					justifyContent: "space-between",*/}
			{/*					paddingTop: isMobile ? "1rem" : "3.75rem"*/}
			{/*				}}*/}
			{/*			>*/}
			{/*				<Col*/}
			{/*					md={6}*/}
			{/*					sm={12}*/}
			{/*					style={{*/}
			{/*						//marginTop: isMobile ? "1rem" : "1rem",*/}
			{/*						fontSize: "1rem",*/}
			{/*						lineHeight: "1.5rem",*/}
			{/*						color: "#666"*/}
			{/*					}}*/}
			{/*				>*/}
			{/*					<div*/}
			{/*						style={{*/}
			{/*							marginBottom: "1.5rem"*/}
			{/*						}}*/}
			{/*					>*/}
			{/*						{lang.CSRPage.mission.description}*/}
			{/*					</div>*/}
			{/*					<div*/}
			{/*						style={{*/}
			{/*							marginBottom: "1.5rem",*/}
			{/*							rowGap: "1rem",*/}
			{/*							display: "flex",*/}
			{/*							flexDirection: "column"*/}
			{/*						}}*/}
			{/*					>*/}
			{/*						{lang.CSRPage.mission.list.map((el, index) => {*/}
			{/*							return (*/}
			{/*								<div*/}
			{/*									style={{*/}
			{/*										display: "flex",*/}
			{/*										flexDirection: "row",*/}
			{/*										columnGap: "1rem",*/}
			{/*										alignItems: "flex-start"*/}
			{/*									}}*/}
			{/*								>*/}
			{/*									<div style={{ position: "relative", top: -2 }}>*/}
			{/*										<svg*/}
			{/*											xmlns="http://www.w3.org/2000/svg"*/}
			{/*											width="14"*/}
			{/*											height="14"*/}
			{/*											viewBox="0 0 14 14"*/}
			{/*											fill="none"*/}
			{/*										>*/}
			{/*											<circle cx="7" cy="7" r="7" fill="#214474" />*/}
			{/*										</svg>*/}
			{/*									</div>*/}
			{/*									<div>{el}</div>*/}
			{/*								</div>*/}
			{/*							)*/}
			{/*						})}*/}
			{/*					</div>*/}
			{/*				</Col>*/}
			{/*				<Col lg={5} md={6} sm={12}>*/}
			{/*					<img*/}
			{/*						src={require("../../assets/heart.png")}*/}
			{/*						style={{ width: "100%" }}*/}
			{/*					/>*/}
			{/*				</Col>*/}
			{/*			</Row>*/}
			{/*			<Row*/}
			{/*				style={{*/}
			{/*					marginBottom: isMobile ? "1.5rem" : "3rem",*/}
			{/*					justifyContent: "space-between",*/}
			{/*					flexDirection: isMobile ? "column-reverse" : "row"*/}
			{/*				}}*/}
			{/*			>*/}
			{/*				<Col lg={5} md={6} sm={12} style={{ alignItems: "center" }}>*/}
			{/*					<img*/}
			{/*						src={require("../../assets/5-pilar.png")}*/}
			{/*						style={{ width: "100%" }}*/}
			{/*					/>*/}
			{/*				</Col>*/}
			{/*				<Col*/}
			{/*					md={6}*/}
			{/*					sm={12}*/}
			{/*					style={{*/}
			{/*						//marginTop: isMobile ? "1rem" : "3.75rem",*/}
			{/*						fontSize: "1rem",*/}
			{/*						lineHeight: "1.5rem",*/}
			{/*						color: "#666"*/}
			{/*					}}*/}
			{/*				>*/}
			{/*					<div*/}
			{/*						style={{*/}
			{/*							marginBottom: "1.5rem"*/}
			{/*						}}*/}
			{/*					>*/}
			{/*						{lang.CSRPage.pillar.description}*/}
			{/*					</div>*/}
			{/*					<div*/}
			{/*						style={{*/}
			{/*							marginBottom: "1.5rem",*/}
			{/*							rowGap: "1rem",*/}
			{/*							display: "flex",*/}
			{/*							flexDirection: "column"*/}
			{/*						}}*/}
			{/*					>*/}
			{/*						{lang.CSRPage.pillar.list.map((el, index) => {*/}
			{/*							return (*/}
			{/*								<div*/}
			{/*									style={{*/}
			{/*										display: "flex",*/}
			{/*										flexDirection: "row",*/}
			{/*										columnGap: "1rem",*/}
			{/*										alignItems: "flex-start"*/}
			{/*									}}*/}
			{/*								>*/}
			{/*									<div style={{ position: "relative", top: -2 }}>*/}
			{/*										<svg*/}
			{/*											xmlns="http://www.w3.org/2000/svg"*/}
			{/*											width="14"*/}
			{/*											height="14"*/}
			{/*											viewBox="0 0 14 14"*/}
			{/*											fill="none"*/}
			{/*										>*/}
			{/*											<circle cx="7" cy="7" r="7" fill="#214474" />*/}
			{/*										</svg>*/}
			{/*									</div>*/}
			{/*									<div>{el}</div>*/}
			{/*								</div>*/}
			{/*							)*/}
			{/*						})}*/}
			{/*					</div>*/}
			{/*				</Col>*/}
			{/*			</Row>*/}
			{/*		</Container>*/}
			{/*	</Container>*/}
			{/*</div>*/}
			<Row style={{ backgroundColor: "#F7F7F8" }}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="1440"
					height="60"
					viewBox="0 0 1440 60"
					fill="none"
					preserveAspectRatio="none"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M0 32.8545L39.6 27.1991C80.4 21.5438 159.6 10.2332 240 4.57784C320.4 -1.07748 399.6 -1.07748 480 2.31571C560.4 5.70891 639.6 12.4953 720 11.3642C800.4 10.2332 879.6 1.18465 960 6.83997C1040.4 12.4953 1119.6 32.8545 1200 36.2477C1280.4 39.6408 1359.6 26.0681 1400.4 19.2817L1440 12.4953V60H1400.4C1359.6 60 1280.4 60 1200 60C1119.6 60 1040.4 60 960 60C879.6 60 800.4 60 720 60C639.6 60 560.4 60 480 60C399.6 60 320.4 60 240 60C159.6 60 80.4 60 39.6 60H0V32.8545Z"
						fill="white"
					/>
				</svg>
			</Row>
			{/*<div style={{ paddingBottom: "9rem" }}>*/}
			{/*	<Container>*/}
			{/*		<Container>*/}
			{/*			<div style={{ marginBottom: isMobile ? "2.25rem" : "3rem" }}>*/}
			{/*				<div*/}
			{/*					style={{*/}
			{/*						fontSize: "1.75rem",*/}
			{/*						fontWeight: 700,*/}
			{/*						lineHeight: "2.25rem",*/}
			{/*						marginBottom: isMobile ? "0.5rem" : "0.75rem"*/}
			{/*					}}*/}
			{/*				>*/}
			{/*					{lang.CSRPage.section2.title}*/}
			{/*				</div>*/}
			{/*				<div*/}
			{/*					style={{*/}
			{/*						fontSize: "1rem",*/}
			{/*						color: "#666",*/}
			{/*						lineHeight: "1.5rem"*/}
			{/*					}}*/}
			{/*				>*/}
			{/*					{lang.CSRPage.section2.description}*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Row style={{ rowGap: "1.5rem" }}>*/}
			{/*				{lang.CSRPage.section2.list.map((el, index) => {*/}
			{/*					return (*/}
			{/*						<CSRRow*/}
			{/*							data={{*/}
			{/*								...el,*/}
			{/*								thumbnail: require("../../assets/csr-thumbnail.png"),*/}
			{/*								index: index*/}
			{/*							}}*/}
			{/*						/>*/}
			{/*					)*/}
			{/*				})}*/}
			{/*			</Row>*/}
			{/*			<div*/}
			{/*				style={{*/}
			{/*					display: "flex",*/}
			{/*					justifyContent: "center",*/}
			{/*					columnGap: "2rem",*/}
			{/*					alignItems: "center",*/}
			{/*					marginTop: "3rem",*/}
			{/*					marginBottom: isMobile ? "3.75rem" : "6.5rem"*/}
			{/*				}}*/}
			{/*			>*/}
			{/*				<div>*/}
			{/*					<svg*/}
			{/*						xmlns="http://www.w3.org/2000/svg"*/}
			{/*						width="24"*/}
			{/*						height="24"*/}
			{/*						viewBox="0 0 24 24"*/}
			{/*						fill="none"*/}
			{/*					>*/}
			{/*						<g opacity="0.4">*/}
			{/*							<path*/}
			{/*								d="M15.75 19.5L8.25 12L15.75 4.5"*/}
			{/*								stroke="#214474"*/}
			{/*								stroke-width="1.5"*/}
			{/*								stroke-linecap="round"*/}
			{/*								stroke-linejoin="round"*/}
			{/*							/>*/}
			{/*						</g>*/}
			{/*					</svg>*/}
			{/*				</div>*/}

			{/*				<div>{lang.CSRPage.section2.page} 1 {lang.CSRPage.section2.outOf} 8</div>*/}
			{/*				<div>*/}
			{/*					<svg*/}
			{/*						xmlns="http://www.w3.org/2000/svg"*/}
			{/*						width="24"*/}
			{/*						height="24"*/}
			{/*						viewBox="0 0 24 24"*/}
			{/*						fill="none"*/}
			{/*					>*/}
			{/*						<path*/}
			{/*							d="M8.25 4.5L15.75 12L8.25 19.5"*/}
			{/*							stroke="#214474"*/}
			{/*							stroke-width="1.5"*/}
			{/*							stroke-linecap="round"*/}
			{/*							stroke-linejoin="round"*/}
			{/*						/>*/}
			{/*					</svg>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</Container>*/}
			{/*	</Container>*/}
			{/*</div>*/}
		</>
	)
}
