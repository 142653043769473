import { Container, Row } from "react-bootstrap"
import { useOutletContext } from "react-router-dom"
import { Link } from "react-router-dom"

export default function CSRDetailPage() {
	const [isMobile, setIsMobile, lang, setLang] = useOutletContext()

	return (
		<>
			<Container style={{}}>
				<Row style={{ marginBottom: isMobile ? "1.5rem" : "3rem" }}>
        <Link className="text-decoration-none" to={"/csr"}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							marginTop: isMobile ? "1rem" : "3rem",
              gap: '1rem',
              color: '#214474',
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							preserveAspectRatio="none"
						>
							<path
								d="M10.5 19.5L3 12M3 12L10.5 4.5M3 12H21"
								stroke="#214474"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div>{lang.CSRDetailPage.back} CSR</div>
					</div>
          </Link>
				</Row>
				<Row style={{ marginBottom: isMobile ? "1.5rem" : "3rem" }}>
					<img src={require("../../assets/csr-hero.png")} width={"100%"} />
				</Row>
				<Row>
					<Row>
						<div
							style={{
								fontWeight: 700,
								fontSize: "1.25rem",
								lineHeight: "1.75rem",
								marginBottom: isMobile ? "0.5rem" : "0.75rem"
							}}
						>
							{lang.CSRDetailPage.title}
						</div>
					</Row>
					<Row>
						<div
							style={{
								fontSize: "1rem",
								lineHeight: "1.5rem",
								color: "#666",
								marginBottom: isMobile ? "1rem" : "1.5rem"
							}}
						>
							31 Agustus 2023 • 8 {lang.CSRDetailPage.readingMinutes}
						</div>
					</Row>
					<Row
						style={{
							fontSize: "1rem",
							lineHeight: "1.5rem",
							color: "#666",
							marginBottom: "3.75rem"
						}}
					>
						{lang.CSRDetailPage.post}
					</Row>
				</Row>
			</Container>
		</>
	)
}
