export default [
    {
        title: 'Ini baru mie kering yang enaknya gak main-main',
        url: '',
    },
    {
        title: 'Proses Pembuatan Mie Kering',
        url: ''
    },
    {
        title: 'Pengolahan Tepung',
        url: ''
    },
    {
        title: 'Pengolahan mie kering',
        url: ''
    }
]