import {Button, Col, Container, Image, Row} from "react-bootstrap"
import Palette from "../utils/Palette"
import MockupImage1 from "../../assets/banner/banner_1.png"
import MockupImage2 from "../../assets/banner/banner_2.png"
import MockupImageAbout from "../../assets/mi-2.png"
import MockupImage from "../../assets/mockup-image.png"
import {Icon} from "@iconify/react"
import CustomHeading from "../reusable/CustomHeading"
import {Link, useNavigate, useOutletContext} from "react-router-dom"
import {Swiper, SwiperSlide} from "swiper/react"

import {MediaPlayer, MediaProvider} from "@vidstack/react"
import {
    defaultLayoutIcons,
    DefaultVideoLayout
} from "@vidstack/react/player/layouts/default"
import "@vidstack/react/player/styles/default/theme.css"
import "@vidstack/react/player/styles/default/layouts/video.css"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Keyboard,
    Autoplay
} from "swiper/modules"
import VideoProduct from "../../models/VideoProduct"
import CustomVideo from "../reusable/CustomVideo"

export default function HomePage({}) {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    const navigate = useNavigate()
    const renderHeroSection = () => {
        return (
            <div
                style={{
                    //backgroundColor: Palette.THEME_BLUE,
                    width: "100%",
                    color: "white",
                    backgroundImage: isMobile ? "" : `url("${MockupImage1}")`,
                    backgroundColor: isMobile ? Palette.THEME_BLUE : "",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        position: "relative",
                        top: isMobile ? "30vh" : "30vh",
                        zIndex: 100
                    }}
                    className="swipper-nav"
                >
                    <Button
                        role="presentation"
                        className="swipper-btn swipper-arrow-left"
                    >
						<span aria-label="Previous">
							<Icon icon="mingcute:left-fill"></Icon>
						</span>
                    </Button>
                    <Button
                        role="presentation"
                        className="swipper-btn swipper-arrow-right"
                    >
						<span aria-label="Next">
							<Icon icon="mingcute:right-fill"></Icon>
						</span>
                    </Button>
                </div>
                <Container
                    style={{
                        marginBottom: isMobile ? 0 : "12rem",
                        marginTop: isMobile ? "-5rem" : ""
                    }}
                >
                    <Swiper
                        keyboard={{
                            enabled: true
                        }}
                        pagination={{
                            paginationClickable: true,
                            dynamicBullets: true,
                            clickable: true,
                            keyboard: {
                                enabled: true
                            }
                            // progressbarOpposite: true
                        }}
                        navigation={!isMobile && {
                            nextEl: ".swipper-arrow-right",
                            prevEl: ".swipper-arrow-left"
                        }}
                        modules={[
                            Navigation,
                            Pagination,
                            Scrollbar,
                            A11y,
                            Keyboard,
                            Autoplay
                        ]}
                        className="mySwiper"
                    >
                        {lang.HomePage.hero.map((thumb) => {
                            return (
                                <>
                                    <SwiperSlide>
                                        <Row className="mt-5" style={{padding: '2.5rem'}}>
                                            <Col className={isMobile ? 'mt-5' : ''} md={7}>
                                                <h2
                                                    style={{
                                                        fontWeight: 700,
                                                        fontSize: "2.5em",
                                                        lineHeight: "1.3em"
                                                    }}
                                                >
                                                    {thumb.title}
                                                </h2>
                                                <div
                                                    style={{padding: "0 5px 0 5px"}}
                                                    className={"mt-3 mb-3"}
                                                >
                                                    <p>
                                                        {thumb.description}
                                                    </p>
                                                    <Button
                                                        className="btn custom-button secondary"
                                                        onClick={() => {
                                                            navigate('/products')
                                                        }}
                                                    >
                                                        {thumb.button}
                                                    </Button>
                                                </div>

                                                {/*<Link to={'/products'}>*/}

                                                {/*    <Button className="custom-button secondary">*/}
                                                {/*        {thumb.button}*/}
                                                {/*    </Button>*/}
                                                {/*</Link>*/}
                                            </Col>
                                        </Row>
                                    </SwiperSlide>
                                </>
                            )
                        })}
                    </Swiper>
                </Container>
                {isMobile && (
                    <div
                        style={{
                            //position: "relative",
                            width: "100%",
                            height: "20rem",
                            color: "white",
                            //backgroundColor: ,
                            backgroundImage: `url("${MockupImage2}")`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center top"
                            //backgroundAttachment: "scroll",
                            //bottom: -10,
                            //scale: 5,
                        }}
                    />
                )}
                <div style={{zIndex: 3}}>
                    <svg
                        width="100vw"
                        height="60"
                        viewBox="0 0 1440 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M0 0C581.004 32.7212 896.996 33.906 1440 0V60H0V0Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>
        )

        return {
            /* <>
                <div
                    //className="mb-3"
                    style={{
                        backgroundImage: `url("${MockupImage1}")`,
                        backgroundColor: Palette.THEME_BLUE,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: isMobile ? "right center" : "center",
                        // background: Palette.THEME_BLUE,
                        // backgroundImage: `url(${MockupImage1})`,
                        color: "white",
                        paddingTop: isMobile ? 0 : 50,
                        height: isMobile ? "75vh" : "50vh",
                        paddingBottom: '12rem'
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            position: "relative",
                            top: "8rem",
                            zIndex: 100
                        }}
                        className="swipper-nav"
                    >
                        <Button
                            role="presentation"
                            className="swipper-btn swipper-arrow-left"
                        >
                            <span aria-label="Previous">
                                <Icon icon="mingcute:left-fill"></Icon>
                            </span>
                        </Button>
                        <Button
                            role="presentation"
                            className="swipper-btn swipper-arrow-right"
                        >
                            <span aria-label="Next">
                                <Icon icon="mingcute:right-fill"></Icon>
                            </span>
                        </Button>
                    </div>
                    <Container>
                        <Swiper
                            keyboard={{
                                enabled: true
                            }}
                            pagination={{
                                paginationClickable: true,
                                dynamicBullets: true,
                                clickable: true,
                                keyboard: {
                                    enabled: true
                                }
                                // progressbarOpposite: true
                            }}
                            navigation={{
                                nextEl: ".swipper-arrow-right",
                                prevEl: ".swipper-arrow-left"
                            }}
                            modules={[
                                Navigation,
                                Pagination,
                                Scrollbar,
                                A11y,
                                Keyboard,
                                Autoplay
                            ]}
                            className="mySwiper"
                        >
                            {Array.from(Array(3).keys()).map((thumb) => {
                                return (
                                    <>
                                        <SwiperSlide>
                                            <Row className="mt-5">
                                                <Col className="mx-4" style={{}} md={6}>
                                                    <b
                                                        style={{
                                                            fontSize: "2.5em",
                                                            lineHeight: "1.3em"
                                                        }}
                                                    >
                                                        Mie Kering Terbaik Nusantara!
                                                    </b>
                                                    <div
                                                        style={{ padding: "0 5px 0 5px" }}
                                                        className={"mt-3 mb-3"}
                                                    >
                                                        Kokiku andalan warga Indonesia. Mie kering Kokiku
                                                        diproduksi dari bahan - <br />
                                                        bahan pilihan yang berkualitas melalui seleksi yang
                                                        ketat sehingga menghasilkan produk sehat untuk kita
                                                        semua.
                                                    </div>
                                                    <Button className="custom-button secondary">
                                                        Lihat Produk
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </SwiperSlide>
                                    </>
                                )
                            })}
                        </Swiper>
                    </Container>
                </div>
                <div
                    style={{ position: "relative", bottom: !isMobile ? '10rem' : 35, paddingTop: '6rem', width: '100%' }}
                    //className="p-0 m-0"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 60"
                        fill="none"
                        preserveAspectRatio="none"
                    >
                        <path
                            width="100%"
                            height="60"
                            d="M0 0C581.004 32.7212 896.996 33.906 1440 0V60H0V0Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </> */
        }
    }
    const renderAboutUsSection = () => {
        return (
            <div>
                <Container className={`${isMobile ? 'pt-3' : 'pt-5'} pb-5`}>
                    {/* <Row>
						<Col
							className={
								"d-flex flex-column align-items-center justify-content-center"
							}
							md={12}
						>
							<CustomHeading text={"Tentang kami"} />
						</Col>
					</Row> */}
                    <Row className={` ${!isMobile && 'mt-5'} justify-content-around`}>
                        <Col md={6} className={"d-flex flex-column"}>
                            <CustomHeading text={lang.HomePage.about.title}/>
                            <p
                                style={{
                                    fontSize: isMobile ? "0.9em" : "1em",
                                    lineHeight: 2,
                                    color: Palette.THEME_TEXT_MAIN,
                                    fontWeight: 600
                                }}
                            >
                                {lang.HomePage.about.description}</p>
                            <div
                                style={{fontSize: "0.9em", fontWeight: 700}}
                                className="mb-4 mt-4"
                            >
                                <Link
                                    style={{color: Palette.THEME_LINK_PRIMARY}}
                                    className="text-decoration-none"
                                    to={"/about"}
                                >
                                    {lang.HomePage.about.more} <Icon icon={"mingcute:arrow-right-line"}/>
                                </Link>
                            </div>
                        </Col>
                        <Col md={5}>

                            <img
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover"
                                }}
                                alt={"about-section-image"}
                                src={MockupImageAbout}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    const renderOurProductsSection = () => {
        return (
            <Container
                style={{
                    marginTop: isMobile ? 10 : 100,
                    marginBottom: isMobile ? 10 : 10
                }}
            >
                <Row>
                    <Col
                        className={
                            "d-flex flex-column justify-content-center " +
                            `${isMobile ? "align-items-start" : "align-items-center"}`
                        }
                        md={12}
                    >
                        <CustomHeading text={lang.HomePage.product.title}/>
                    </Col>
                </Row>

                <Row className={"mt-1 mb-5"}>
                    <p style={{color: Palette.THEME_TEXT_MAIN, fontSize: isMobile ? '0.9em' : '1em'}}
                       className={isMobile ? "text-left" : "text-center"}>
                        {lang.HomePage.product.description}
                    </p>

                    {lang.ProductPage.list.map((v, index) => {
                        return (
                            index < 3 &&
                            <>
                                <Col
                                    md={4}
                                    style={{
                                        height: isMobile ? "fit-content" : "auto",
                                        marginBottom: "1rem"
                                    }}
                                >
                                    {
                                        v?.link ? <Link to={v?.link}>
                                                <img
                                                    style={{
                                                        height: 300,
                                                        width: "100%",
                                                        objectFit: "cover"
                                                    }}
                                                    src={v.image}
                                                />
                                            </Link> :
                                            <img
                                                style={{
                                                    height: 300,
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                                src={v.image}
                                            />
                                    }


                                    <h4 className="py-3" style={{fontSize: "1.3em", fontWeight: 700}}>
                                        {
                                            v?.link ?
                                                <Link className={'product-title-link'} style={{color: Palette.THEME_TEXT_DARK}}
                                                      to={v?.link}>{v?.title}</Link> :
                                                <> {v?.title}</>
                                        }

                                    </h4>

                                    <p
                                        style={{
                                            color: Palette.THEME_TEXT_MAIN,
                                            fontSize: isMobile ? '0.9em' : '1em'
                                        }}
                                    >
                                        {v?.description}
                                    </p>
                                    <div
                                        style={{fontSize: "0.9em", fontWeight: 700}}
                                        className="mb-4 mt-4"
                                    >
                                        {/*<Link*/}
                                        {/*    style={{color: Palette.THEME_LINK_PRIMARY}}*/}
                                        {/*    className="text-decoration-none"*/}
                                        {/*    to={"/product/" + (index + 1)}*/}
                                        {/*>*/}
                                        {/*    {lang.ProductPage.list[index].more} <Icon*/}
                                        {/*    icon={"mingcute:arrow-right-line"}/>*/}
                                        {/*</Link>*/}
                                    </div>
                                </Col>
                            </>
                        )
                    })}
                </Row>
                <div
                    style={{
                        justifyContent: "center",
                        display: "flex",
                        marginBottom: isMobile ? "3rem" : "3.75rem"
                    }}
                >
                    <Link
                        style={{
                            color: Palette.THEME_LINK_PRIMARY,
                            fontWeight: 600,
                            fontSize: "1rem",
                            lineHeight: "1.5rem"
                            //width: 'min-content'
                        }}
                        className="text-decoration-none"
                        to={"/products"}
                    >
                        <div>
                            {lang.HomePage.product.more} <Icon icon={"mingcute:arrow-right-line"}/>
                        </div>
                    </Link>
                </div>
            </Container>
        )
    }

    const renderVideo = () => {
        return (
            <>
                <Container fluid style={{background: "#f7f7f8"}}>
                    <Row>
                        <Col
                            className={
                                "d-flex flex-column justify-content-center " +
                                `${isMobile ? "align-items-start" : "align-items-center"}`
                            }
                            md={12}
                        >
                            <CustomHeading text={lang.HomePage.video.title}/>
                            <p>{lang.HomePage.video.description}</p>
                        </Col>
                    </Row>
                    <Container>
                        <Row className="mb-5">
                            {VideoProduct.map((v, index) => {
                                return (
                                    <>
                                        <Col md={3}>
                                            <CustomVideo/>
                                            <div style={{fontWeight: 600}}>{lang.HomePage.video.list[index].title}</div>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                        <div
                            style={{
                                justifyContent: "center",
                                display: "flex",
                                marginBottom: isMobile ? "3rem" : "3.75rem"
                            }}
                        >
                            <Link
                                style={{
                                    color: Palette.THEME_LINK_PRIMARY,
                                    fontWeight: 600,
                                    fontSize: "1rem",
                                    lineHeight: "1.5rem"
                                    //width: 'min-content'
                                }}
                                className="text-decoration-none"
                                to={"/video-gallery"}
                            >
                                <div>
                                    {lang.HomePage.video.more} <Icon icon={"mingcute:arrow-right-line"}/>
                                </div>
                            </Link>
                        </div>
                    </Container>
                </Container>
            </>
        )
    }

    return (
        <div className={"d-flex flex-column"}>
            {renderHeroSection()}
            {renderAboutUsSection()}
            {renderOurProductsSection()}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 60" fill="none">
                <path
                    width="100%"
                    height="60"
                    d="M0 30.9096C619 -9.8169 831.5 -10.7866 1440 30.9096V60H0V30.9096Z"
                    fill="#F7F7F8"
                />
            </svg>
            {renderVideo()}
        </div>
    )
}
