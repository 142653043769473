import {Container, Row, Col} from "react-bootstrap";
import Palette from "../utils/Palette";

const Hero = ({title, desc}) => {
    return (
        <div
            style={{
                backgroundColor: Palette.THEME_BLUE,
                width: "100%",
                color: "white"
            }}
        >
            <Container
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "4rem 0rem"
                }}
            >
                <Col
                    lg={7}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1.5rem"
                    }}
                >
                    <Row>
                        <h1
                            style={{
                                textAlign: "center",
                                fontSize: "1.75em",
                                fontWeight: 700,
                                alignItems: "center"
                            }}
                        >{title}</h1>
                    </Row>
                    <Row>
                        <p style={{
                            alignSelf: "stretch",
                            color: "#E0E0E0",
                            textAlign: "center",
                            fontSamily: "Open Sans",
                            fontWeight: 500,
                            padding: "0 15px 0 15px"
                        }}>
                            {desc}
                        </p>
                    </Row>
                </Col>
            </Container>
            <svg
                width="100vw"
                height="60"
                viewBox="0 0 1440 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
            >
                <path
                    d="M0 0C581.004 32.7212 896.996 33.906 1440 0V60H0V0Z"
                    fill="white"
                />
            </svg>
        </div>
    )
}

export default Hero;