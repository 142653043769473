import Palette from "../utils/Palette"
import {MENU_HEADER} from "../utils/Menu"
import {Button, Col, Container, Dropdown, Image, Row} from "react-bootstrap"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {useState} from "react"
import {Icon} from "@iconify/react"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import logo from "../../assets/logo.png"
import langId from "../utils/lang/id"
import langEn from "../utils/lang/en"

export default function Header({isMobile, lang, setLang}) {
    const [showMenu, setShowMenu] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const [expand, setExpand] = useState(false)

    const renderLangID = (
        <>
            <Icon icon={'twemoji:flag-indonesia'}/>
            <span style={{marginLeft: 3}}>ID</span>
        </>
    )

    const renderLangEN = (
        <>
            <Icon icon={'twemoji:flag-united-kingdom'}/>
            <span style={{marginLeft: 3}}>EN</span>
        </>
    )
    const mobileNav = () => {
        return (
            <>
                <Navbar
                    collapseOnSelect
                    style={{background: Palette.THEME_BLUE, paddingTop: 30}}
                    key={expand}
                    expand={false}
                >
                    <Container fluid>
                        <Navbar.Brand
                            style={{
                                color: "white",
                                fontWeight: 600
                            }}
                            href="/"
                        >
                            {" "}
                            <Image style={{width: 124, height: 50, marginRight: 10}} src={logo}></Image>
                        </Navbar.Brand>


                        <Navbar.Toggle
                            onClick={() => {
                                setShowMenu(!showMenu)
                            }}
                            style={{border: "none", color: "#fff"}}
                            aria-controls="basic-navbar-nav"
                        >
                            <Icon icon={"heroicons-outline:menu-alt-3"}/>
                        </Navbar.Toggle>


                        <Offcanvas placement="end" show={showMenu}>
                            <Offcanvas.Header
                                style={{
                                    background: Palette.THEME_BLUE,
                                    paddingTop: 35,
                                    fontSize: 18,
                                    width: "100%"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        fontWeight: 600,
                                        fontSize: 18,
                                        color: "white",
                                        alignItems: "center",
                                        verticalAlign: "center"
                                    }}
                                >
                                    <div style={{flex: 1}}>
                                        <Link className="text-decoration-none text-white" to={"/"}>
                                            <Image
                                                style={{width: 124, height: 50, marginRight: 10}}
                                                src={logo}
                                            ></Image>
                                        </Link>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => {
                                                setShowMenu(!showMenu)
                                            }}
                                            style={{color: "white", fontSize: 20}}
                                            variant="link"
                                        >
                                            <Icon icon={"heroicons-outline:menu-alt-2"}/>
                                        </Button>
                                    </div>
                                </div>
                            </Offcanvas.Header>
                            <Offcanvas.Body style={{background: Palette.THEME_BLUE}}>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    {lang.MENU_HEADER.map((obj, index) => {
                                        return (
                                            <Nav.Link
                                                style={{
                                                    color:
                                                        location.pathname === obj.path
                                                            ? "white"
                                                            : Palette.THEME_INACTIVE_GREY
                                                }}
                                                onClick={() => {
                                                    navigate(obj.path)
                                                    setShowMenu(!showMenu)
                                                }}
                                                href="javascript:;"
                                            >
                                                {obj?.name}
                                            </Nav.Link>
                                        )
                                    })}
                                </Nav>
                                <NavDropdown
                                    className="nav-lang"
                                    id="dropdown-language-full"
                                    title={lang === langId ? renderLangID : renderLangEN}
                                    menuVariant="light"
                                    style={{color: '#FFFFFF'}}
                                >
                                    <NavDropdown.Item href="javascript:;" onClick={() =>
                                        setLang(langId)}>
                                        {renderLangID}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="javascript:;" onClick={() =>

                                        setLang(langEn)}>
                                        {renderLangEN}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Container>
                </Navbar>
            </>
        )
    }

    const normalNav = () => {
        return (
            <>
                <div
                    className={
                        "d-flex flex-row align-items-center justify-content-center"
                    }
                    style={{
                        color: "white",
                        background: Palette.THEME_BLUE,
                        height: "auto",
                        width: "100vw",
                        position: "fixed",
                        top: 0,
                        zIndex: 999
                    }}
                >
                    <Container
                        style={{flexDirection: "column", alignItems: "flex-start"}}
                    >
                        {/* <Row style={{ height: 100 }}> */}
                        <Col
                            className={
                                "d-flex flex-row align-items-center justify-content-center"
                            }
                            xxl={12}
                            style={{height: 100, gap: 40}}
                        >
                            <div>
                                <Link className="text-decoration-none text-white" to={"/"}>
                                    <Image
                                        style={{width: 140, marginRight: 10}}
                                        src={logo}
                                    ></Image>
                                </Link>
                            </div>
                            {!isMobile ? (
                                <>
                                    <div style={{gap: 80}} className={"d-flex flex-row"}>
                                        {lang.MENU_HEADER.map((obj, index) => {
                                            return !obj.submenu ? (
                                                <div
                                                    className={"ms-3 me-3"}
                                                    style={{
                                                        fontWeight: 600,
                                                        color:
                                                            location.pathname === obj.path
                                                                ? Palette.YELLOW
                                                                : Palette.THEME_WHITE,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => navigate(obj.path)}
                                                >
                                                    {obj.name}
                                                </div>
                                            ) : (
                                                <Dropdown>
                                                    <div
                                                        style={{display: "flex", flexDirection: "row"}}
                                                    >
                                                        <div
                                                            className={""}
                                                            style={{
                                                                fontWeight: 600,
                                                                color:
                                                                    location.pathname === obj.path
                                                                        ? Palette.YELLOW
                                                                        : Palette.THEME_WHITE,
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => navigate(obj.path)}
                                                        >
                                                            {obj.name}
                                                        </div>
                                                        <Dropdown.Toggle
                                                            className="py-0"
                                                            style={{
                                                                fontWeight: 600,
                                                                textDecoration: "none",
                                                                color: "#FFF"
                                                            }}
                                                            variant="link"
                                                            id="dropdown-basic"
                                                        />
                                                    </div>
                                                    <Dropdown.Menu
                                                        style={{
                                                            borderRadius: 0,
                                                            border: 0,
                                                            boxShadow:
                                                                " 0px 4px 40px 0px rgba(18, 18, 18, 0.08)"
                                                        }}
                                                    >
                                                        {
                                                            obj.submenu.map((sub, index2) => {
                                                                return (
                                                                    <>

                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                navigate(sub.path)
                                                                            }}
                                                                            className={`dropdown-big-custom`}
                                                                            style={{lineHeight: 2.3, fontWeight: 600}}
                                                                        >
                                                                            {sub.name}
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )
                                                            })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )
                                        })}
                                        <NavDropdown
                                            className="nav-lang"
                                            id="dropdown-language-full"
                                            title={lang === langId ? renderLangID : renderLangEN}
                                            menuVariant="light"
                                            style={{color: '#FFFFFF'}}
                                        >
                                            <NavDropdown.Item href="javascript:;" onClick={() => setLang(langId)}>
                                                {renderLangID}
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="javascript:;" onClick={() => setLang(langEn)}>
                                                {renderLangEN}
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                        {/*{lang == langId ? (*/}
                                        {/*    <div*/}
                                        {/*        className={"ms-3 me-3"}*/}
                                        {/*        style={{*/}
                                        {/*            fontWeight: 600,*/}
                                        {/*            color: Palette.THEME_WHITE,*/}
                                        {/*            cursor: "pointer"*/}
                                        {/*        }}*/}
                                        {/*        onClick={() => setLang(langEn)}*/}
                                        {/*    >*/}
                                        {/*        EN*/}
                                        {/*    </div>*/}
                                        {/*) : (*/}
                                        {/*    <div*/}
                                        {/*        className={"ms-3 me-3"}*/}
                                        {/*        style={{*/}
                                        {/*            fontWeight: 600,*/}
                                        {/*            color: Palette.THEME_WHITE,*/}
                                        {/*            cursor: "pointer"*/}
                                        {/*        }}*/}
                                        {/*        onClick={() => setLang(langId)}*/}
                                        {/*    >*/}
                                        {/*        ID*/}
                                        {/*    </div>*/}
                                        {/*)}*/}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Icon
                                        icon={"heroicons-outline:menu-alt-3"}
                                        onClick={() => setShowMenu(!showMenu)}
                                    />
                                </>
                            )}
                        </Col>
                        {/*  </Row> */}
                    </Container>
                </div>
            </>
        )
    }

    const handleRender = () => {
        return isMobile ? mobileNav() : normalNav()
    }

    return handleRender()
}
