import { Icon } from "@iconify/react"
import { Col, Container, Row, Form, Button } from "react-bootstrap"
import Palette from "../utils/Palette"
import { useEffect, useState } from "react"
import * as Yup from "yup";
import Hero from "../reusable/Hero"
import { useOutletContext } from "react-router-dom"
import { useFormik } from "formik";
import Swal from 'sweetalert2'
import Contact from '../../models/Contact'
export default function ContactPage() {
	const [isMobile, setIsMobile, lang, setLang] = useOutletContext()

	const schema = Yup.object().shape({
		full_name: Yup.string().required('Nama harus diisi'),
		email: Yup.string().email().required('Email harus diisi'),
		phone_number: Yup.number().required('Phone Number/Whatsapp harus diisi'),
		subject: Yup.string().required('Subjek harus diisi'),
		message: Yup.string().required('Pesan harus diisi')
	});
	const formik = useFormik({
		initialValues: {
			full_name: "",
			email: "",
			phone_number: "",
			subject: "",
			message: ""
		},
		validationSchema: schema,
		onSubmit: async (values, { resetForm }) => {
			try {
				await Contact.sendContact(values)
			} catch (e) {
			}
			resetForm();
			Swal.fire({
				title: 'success!',
				text: 'Thank you, we will provide feedback from your message soon.',
				icon: 'success',
				confirmButtonText: 'Finish'
			})
			// setFormState(values);
		}
	});
	return (
		<>
			<Hero
				title={lang.ContactPage.hero.title}
				desc={lang.ContactPage.hero.description}
			/>
			<Container
				style={{
					marginTop: isMobile ? "1rem" : "3.75rem",
					marginBottom: isMobile ? "3rem" : "10rem"
				}}
			>
				<Row className="mt-5 mb-5" style={{ rowGap: "1.5rem" }}>
					<Col lg={3} md={6} sm={12}>
						<img
							src={require("../../assets/office_photo.jpeg")}
							style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
						/>
					</Col>
					<Col lg={9} md={6} sm={12}>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d247.93153089733482!2d106.68216548903617!3d-6.143529435684209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sid!4v1713760861535!5m2!1sen!2sid"
							width="100%"
							height="500"
							style={{ border: 0 }}
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6} sm={12}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								rowGap: "0.75rem",
								marginBottom: "1.5rem"
							}}
						>
							<div
								style={{
									fontSize: "1.25rem",
									fontWeight: 700,
									lineHeight: "1.75rem"
								}}
							>
								{lang.ContactPage.information.location}
							</div>
							<p
								style={{
									fontSize: "1em",
									lineHeight: "1.5rem",
									color: "#666"
								}}
							>
								PT. Samudera Marga Wisesa
								Ruko Duta Gardenia Blok D/28, Jurumudi Baru, Tangerang, Banten 15124
							</p>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								rowGap: "0.75rem",
								marginBottom: "1.5rem"
							}}
						>
							<div
								style={{
									fontSize: "1.25rem",
									fontWeight: 700,
									lineHeight: "1.75rem"
								}}
							>
								{lang.ContactPage.information.email}
							</div>
							<p
								style={{
									fontSize: "1rem",
									lineHeight: "1.5rem",
									color: "#666"
								}}
							>
								hello@smwfood.com
							</p>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								rowGap: "0.75rem",
								marginBottom: "1.5rem"
							}}
						>
							<div
								style={{
									fontSize: "1.25rem",
									fontWeight: 700,
									lineHeight: "1.75rem"
								}}
							>
								{lang.ContactPage.information.callCenter}
							</div>
							<p
								style={{
									fontSize: "1rem",
									lineHeight: "1.5rem",
									color: "#666"
								}}
							>
								+62 812-9243-2986
							</p>
						</div>
					</Col>
					<Col md={6} sm={12}>
						<Row
							style={{
								display: "flex",
								flexDirection: "column",
								rowGap: "1rem",
								paddingRight: "calc(var(--bs-gutter-x) * .5)",
								paddingLeft: "calc(var(--bs-gutter-x) * .5)"
							}}
						>
							<Form onSubmit={formik.handleSubmit} className="form-contact">
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#E8E8E8",
											border: 0
											//width: '100%'
										}}
										name="full_name"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.full_name}
										className="contact-form-input" type="text" placeholder={lang.ContactPage.form.name} />

									<Form.Text className="text-danger">
										{formik.touched.full_name && formik.errors.full_name ? (
											<div className="text-danger">{formik.errors.full_name}</div>
										) : null}
									</Form.Text>

								</Form.Group>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#E8E8E8",
											border: 0
											//width: '100%'
										}}
										name="phone_number"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.phone_number}
										className="contact-form-input" type="number" placeholder={lang.ContactPage.form.phone} />

									<Form.Text className="text-danger">
										{formik.touched.phone_number && formik.errors.phone_number ? (
											<div className="text-danger">{formik.errors.phone_number}</div>
										) : null}
									</Form.Text>

								</Form.Group>


								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#E8E8E8",
											border: 0
											//width: '100%'
										}}
										name="email"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.email}
										className="contact-form-input" type="text" placeholder={lang.ContactPage.form.email} />

									<Form.Text className="text-danger">
										{formik.touched.email && formik.errors.email ? (
											<div className="text-danger">{formik.errors.email}</div>
										) : null}
									</Form.Text>

								</Form.Group>

								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#E8E8E8",
											border: 0
											//width: '100%'
										}}
										name="subject"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.subject}
										className="contact-form-input" type="text" placeholder={lang.ContactPage.form.subject} />

									<Form.Text className="text-danger">
										{formik.touched.subject && formik.errors.subject ? (
											<div className="text-danger">{formik.errors.subject}</div>
										) : null}
									</Form.Text>

								</Form.Group>



								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#E8E8E8",
											border: 0
										}}
										as="textarea"
										rows={3}
										name="message"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.message}
										className="contact-form-input"

										placeholder={lang.ContactPage.form.message} />

									<Form.Text className="text-danger">
										{formik.touched.message && formik.errors.message ? (
											<div className="text-danger">{formik.errors.message}</div>
										) : null}
									</Form.Text>

								</Form.Group>
								<div className="d-grid gap-2">
									<Button
										type="submit"
										block
										style={{
											color: "white",
											backgroundColor: Palette.THEME_BLUE,
											border: 0
										}}
									>
										{lang.ContactPage.form.send}
									</Button>
								</div>

							</Form>


						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}
