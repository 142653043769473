import {Container, Row, Col} from "react-bootstrap"
import Hero from "../reusable/Hero"
import {useOutletContext, useParams} from "react-router-dom"
import {useEffect, useState} from "react";
import Palette from "../utils/Palette";

export default function ProductDetailPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext()
    const {id} = useParams()
    const [product, setProduct] = useState(null)
    const init = () => {
        let result = lang.ProductDetailPage.filter((value) => {
            return value?.id === parseInt(id)
        })
        setProduct(result?.length > 0 ? result[0] : null)
    }
    useEffect(() => {
        init()
    }, [id]);
    return (
        <>
            <Hero
                title={product?.hero?.title}
                desc={product?.hero?.description}
            />
            <Container
                style={{
                    marginTop: isMobile ? "1rem" : "3.75rem",
                    marginBottom: isMobile ? "3rem" : "8rem"
                }}
            >
                <div
                    style={{
                        color: "#666",
                        fontSize: "1rem",
                        lineHeight: "1.5rem",
                        marginBottom: isMobile ? "1.5rem" : "3rem"
                    }}
                >
                    {product?.description}
                </div>
                <div>
                    <Row>

                        <Col xs={4}>
                            <img
                                src={product?.imageList?.[0]?.img}
                                style={{width: "100%"}}
                            />
                        </Col>


                        <Col
                            xs={4}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}
                        >
                            <Row>
                                <img
                                    src={product?.imageList?.[1]?.img}
                                    style={{width: "100%"}}
                                />
                            </Row>
                            <Row>
                                <img
                                    src={product?.imageList?.[2]?.img}
                                    style={{width: "100%"}}
                                />
                            </Row>
                        </Col>
                        <Col xs={4}>
                            <img
                                src={product?.imageList?.[3]?.img}
                                style={{width: "100%"}}
                            />
                        </Col>
                    </Row>
                </div>
            </Container>
            <Row>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1440"
                    height="60"
                    viewBox="0 0 1440 60"
                    fill="none"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M0 30.9096C619 -9.8169 831.5 -10.7866 1440 30.9096V60H0V30.9096Z"
                        fill="#F7F7F8"
                    />
                </svg>
            </Row>

            <div
                style={{
                    backgroundColor: "#F7F7F8",
                    paddingBottom: isMobile ? "4.5rem" : "10.75rem"
                }}
            >
                <Container>
                    <Container>
                        <Row style={{rowGap: '1rem', paddingTop: isMobile ? "1rem" : "5rem",}}>
                            {
                                product?.list?.map((data) => {
                                    return (
                                        <>
                                            <Col md={3} xs={6} style={{padding: "0rem 1rem"}}>
                                                <div style={{backgroundColor: "white", borderRadius: '0.5rem'}}>
                                                    <div>
                                                        <img src={data.img} width={"100%"}/>
                                                    </div>
                                                    <div style={{padding: "1rem 1.5rem"}}>
                                                        <div
                                                            style={{
                                                                fontSize: "1.25rem",
                                                                lineHeight: "1.75rem",
                                                                marginBottom: "1rem",
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            {data.name}
                                                        </div>
                                                        <p
                                                            style={{
                                                                color: Palette.THEME_TEXT_MAIN,
                                                                fontSize: "1rem",
                                                                lineHeight: "1.5rem",
                                                                marginBottom: "0.5rem"
                                                            }}
                                                        >
                                                            {data.weight}
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: Palette.THEME_TEXT_MAIN,
                                                                fontSize: "1rem",
                                                                lineHeight: "1.5rem",
                                                                marginBottom: "0.5rem"
                                                            }}
                                                        >
                                                            {data.isi}
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: Palette.THEME_TEXT_MAIN,
                                                                fontSize: "1rem",
                                                                lineHeight: "1.5rem"
                                                                //marginBottom: '0.5rem',
                                                            }}
                                                        >
                                                            {data.dalam_dus}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                    )
                                })
                            }

                        </Row>
                    </Container>
                </Container>
            </div>
        </>
    )
}
