import Palette from "../utils/Palette";
import {MENU_FOOTER} from "../utils/Menu";
import {Col, Container, Row, Image, Modal} from "react-bootstrap";
import {Icon} from "@iconify/react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import logo from '../../assets/logo.png'
import {useState} from "react";
import {QRCode} from "react-qrcode-logo";

export default function Footer({isMobile, lang, setLang}) {
    const navigate = useNavigate()
    const location = useLocation()
    const [showDialog, setShowDialog] = useState(false)
    return (
        <>
            <Modal onHide={() => {
                setShowDialog(false)
            }} show={showDialog}>

                <Modal.Header style={{border: 'none'}} closeButton>

                </Modal.Header>
                <Modal.Body style={{paddingTop: 0}} className={'text-center'}>
                    <Image style={{
                        objectFit: 'contain',
                        width: 100, height: 100,
                        background: '#1b419b',
                        borderRadius: '100%'
                    }} src={logo}></Image>
                    <Modal.Title className={'mt-2'}>
                        <h3 className={'text-center'} style={{fontWeight: 600}}>PT.SMW</h3>

                    </Modal.Title>
                    <p className={'text-center'}>Akun Whatsapp Business</p>
                    <div className={'text-center'}>
                        <QRCode value="https://wa.me/6281292432986"/>
                    </div>

                </Modal.Body>
            </Modal>
            <div className={"d-flex flex-row align-items-center justify-content-center"} style={{
                color: "white",
                background: Palette.THEME_BLUE,
                width: "100vw",
                paddingTop: 50,
                paddingBottom: 50
            }}>
                <Container>
                    <Row>
                        <Col
                            className={"d-flex flex-row align-items-start justify-content-start " + isMobile ? "mb-1" : ""}
                            md={5}>
                            <Link className="text-white text-decoration-none" to={'/'}>
                                <div className="d-flex">
                                    <Image style={{width: 200, marginRight: 10}} src={logo}></Image>
                                </div>
                            </Link>
                            <div className={"d-flex flex-column"}>
                                <div className={"mt-4 mb-4"}>
                                    {lang.footer.description}
                                </div>
                                <div className={"d-flex flex-row mb-4"}>
                                    <div className={"ms-3 me-3"} style={{fontSize: "1.2em"}}>
                                        <Link className="text-white" to={'/'}>
                                            <Icon icon="ic:baseline-facebook"/>
                                        </Link>

                                    </div>
                                    <div className={"ms-3 me-3"} style={{fontSize: "1.2em"}}>
                                        <Link className="text-white" to={'/'}>
                                            <Icon icon="ri:instagram-fill"/>
                                        </Link>
                                    </div>
                                    <div className={"ms-3 me-3"} style={{fontSize: "1.2em"}}>
                                        <Link className="text-white" to={'/'}>
                                            <Icon icon="ri:twitter-fill"/>
                                        </Link>
                                    </div>
                                    <div className={"ms-3 me-3"} style={{fontSize: "1.2em"}}>
                                        <Link className="text-white" to={'/'}>
                                            <Icon icon="mdi:location"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginLeft: 'auto', marginRight: 'auto'}}
                             className={"d-flex flex-column" + isMobile ? "mb-4" : ""} md={3}>
                            <h4 style={{fontWeight: 'bold'}} className={"mb-4"}>{lang.footer.shortcutLink}</h4>
                            <Row
                                className={"mt-1 mb-1"}
                                style={{
                                    color: Palette.THEME_WHITE
                                }}
                            >
                                {
                                    lang.MENU_FOOTER?.map(((obj, index) => {
                                        return (
                                            <Col className="mb-2 mt-2 ml-auto" md={12}>
                                                <Link style={{
                                                    fontWeight: 600,
                                                    textDecoration: 'none',
                                                    color: location.pathname === obj.path ? Palette.YELLOW
                                                        : Palette.THEME_WHITE,
                                                }} to={obj.path}>{obj?.name}</Link>
                                            </Col>
                                        )
                                    }))
                                }

                            </Row>

                        </Col>
                        <Col style={{marginLeft: 'auto', marginRight: 'auto'}}
                             className={"d-flex flex-column" + isMobile ? "mb-4" : ""} md={3}>
                            <h4 style={{fontWeight: 'bold'}} className={"mb-4"}>{lang.footer.callCenter}</h4>
                            <Row
                                className={"mt-1 mb-1"}
                                style={{
                                    color: Palette.THEME_WHITE
                                }}
                            >
                                <div className="d-flex">
                                    <Icon width={20} height={20} icon={'mingcute:phone-call-fill'}></Icon>
                                    <a onClick={() => {
                                        setShowDialog(true)
                                    }} href={'javascript:;'} style={{
                                        marginLeft: 5,
                                        fontWeight: 600,
                                        textDecoration: 'none',
                                        color: Palette.THEME_WHITE
                                    }} className="ml-1">
                                        +62 812-9243-2986
                                    </a>

                                </div>

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"d-flex flex-column " + isMobile ? "mb-4" : ""} md={5}>
                            <div style={{
                                fontWeight: 500,
                                color: Palette.THEME_WHITE
                            }} className={"d-flex flex-row mb-4"}>
                                Copyright {new Date().getFullYear()}. All Rights Reserved
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )


}