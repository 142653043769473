import { useOutletContext } from "react-router-dom";
import Palette from "../utils/Palette";

export default function CustomHeading({ text }) {
    const [isMobile, setIsMobile] = useOutletContext()

    return <h2
        style={{
            fontSize: "1.75rem",
            fontWeight: 700,
            lineHeight: "2.25rem",
            marginBottom: "1.5rem"
        }}
    >
        {text}
    </h2>
} 