import { Col, Container, Image, Row } from "react-bootstrap"
import CustomHeading from "../reusable/CustomHeading"
import Palette from "../utils/Palette"
import mockupServicesSection1 from '../../assets/mockupServices1.png'
import mockupServicesSection2 from '../../assets/mockupServices2.png'
export default function ServicePage() {
    const renderHero = () => {
        return (
            <div
                style={{
                    backgroundColor: Palette.THEME_BLUE,
                    width: '100%',
                    color: 'white',
                    padding: '4rem 0rem'
                }}
            >
                <Container style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col
                        lg={7}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1.5rem',
                        }}
                    >
                        <Row
                            style={{
                                textAlign: 'center',
                                fontSize: '1.75rem',
                                fontWeight: 700,
                                alignItems: 'center',
                            }}>
                            Our Services
                        </Row>
                        <Row
                            style={{
                                textAlign: 'center',
                                fontSize: '0.875em',
                                fontWeight: 400,
                                alignItems: 'center',
                                padding: '0 15px 0 15px'
                            }}>
                            Through our affiliate coil center, we provide these services
                        </Row>
                    </Col>
                </Container>
            </div >
        )
    }

    return (
        <>
            {renderHero()}
            <Container>
                <Row className="mt-5 mb-5">
                    <Col className={"d-flex flex-column align-items-center justify-content-center"} md={12} style={{ overflowX: 'clip', textOverflow: 'clip' }}>
                        <CustomHeading text={"SHEARING OR CUTTING"} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={3}>
                        <Image className="w-100" style={{ maxHeight: '200px' }} alt={`img-${mockupServicesSection1}`} src={mockupServicesSection1} fluid />
                    </Col>
                    <Col style={{ fontSize: '0.8em' }}>
                        Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.

                    </Col>
                </Row>

                <Row className="mt-5 mb-5">
                    <Col className={"d-flex flex-column align-items-center justify-content-center"} md={12}>
                        <CustomHeading text={"SLITTING"} />
                    </Col>
                </Row>
                <Row className="mb-3 mb-5">
                    <Col md={3}>
                        <Image className="w-100" style={{ maxHeight: '200px' }} alt={`img-${mockupServicesSection1}`} src={mockupServicesSection2} fluid />
                    </Col>
                    <Col style={{ fontSize: '0.8em' }}>
                        Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                    </Col>
                </Row>

            </Container>
        </>
    )
}