import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
export default function CustomVideo() {
    return (
        <>
            <div className="videos">
                <div className="video-wrap" style={{ height: 300, background: '#000', borderRadius: 20 }}>
                    <a href="http://example.com/">
                        <div className="play-btn">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
                                <path d="M16 0.25C12.885 0.25 9.83985 1.17372 7.24978 2.90435C4.6597 4.63499 2.64098 7.0948 1.4489 9.97274C0.256824 12.8507 -0.0550783 16.0175 0.552639 19.0727C1.16036 22.1279 2.6604 24.9343 4.86307 27.1369C7.06575 29.3396 9.87213 30.8397 12.9273 31.4474C15.9825 32.0551 19.1493 31.7432 22.0273 30.5511C24.9052 29.359 27.365 27.3403 29.0957 24.7502C30.8263 22.1602 31.75 19.1151 31.75 16C31.75 11.8228 30.0906 7.81677 27.1369 4.86307C24.1832 1.90937 20.1772 0.25 16 0.25ZM24.3779 17.0069L10.8779 23.7569C10.7063 23.8426 10.5157 23.883 10.3241 23.8744C10.1325 23.8657 9.94633 23.8082 9.78322 23.7073C9.62012 23.6064 9.48551 23.4655 9.39218 23.298C9.29886 23.1304 9.24992 22.9418 9.25001 22.75V9.25C9.25011 9.05831 9.29919 8.86982 9.3926 8.70243C9.486 8.53504 9.62063 8.39428 9.7837 8.29353C9.94678 8.19278 10.1329 8.13537 10.3244 8.12674C10.5159 8.11812 10.7064 8.15856 10.8779 8.24425L24.3779 14.9943C24.5645 15.0878 24.7214 15.2314 24.8311 15.409C24.9407 15.5866 24.9988 15.7913 24.9988 16C24.9988 16.2087 24.9407 16.4134 24.8311 16.591C24.7214 16.7686 24.5645 16.9122 24.3779 17.0057V17.0069Z" fill="#214474" />
                            </svg> */}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.69 311.69">
                                <path d="M155.84,0A155.85,155.85,0,1,0,311.69,155.84,155.84,155.84,0,0,0,155.84,0Zm0,296.42A140.58,140.58,0,1,1,296.42,155.84,140.58,140.58,0,0,1,155.84,296.42Z" />
                                <polygon points="218.79 155.84 119.22 94.34 119.22 217.34 218.79 155.84" />
                            </svg>
                        </div>
                    </a>

                </div>
            </div>

            {/* <MediaPlayer title="Sprite Fight" src="https://stream.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/low.mp4">
                <MediaProvider />
                <DefaultVideoLayout thumbnails="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/storyboard.vtt" icons={defaultLayoutIcons} />
            </MediaPlayer>; */}
        </>
    )
}