import Hero from "../reusable/Hero"
import {useOutletContext} from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import CustomHeading from "../reusable/CustomHeading";
import Palette from "../utils/Palette";
import {Icon} from "@iconify/react";

export default function ProductPage() {
    const [isMobile, setIsMobile, lang, setLang] = useOutletContext();

    return (
        <>
            <Hero
                title={lang.ProductPage.hero.title}
                desc={lang.ProductPage.hero.description}
            />
            <Container
                style={{
                    marginTop: isMobile ? "1rem" : "3.75rem",
                    marginBottom: isMobile ? "3rem" : "12rem"
                }}
            >

                <Row className={"mt-1 mb-5"}>
                    {lang.ProductPage.list.map((v, index) => {
                        return (
                            <>
                                <Col
                                    md={4}
                                    style={{
                                        height: isMobile ? "fit-content" : "auto",
                                        marginBottom: "1rem"
                                    }}
                                >
                                    {
                                        v?.link ? <Link to={v?.link}>
                                                <img
                                                    style={{
                                                        height: 300,
                                                        width: "100%",
                                                        objectFit: "cover"
                                                    }}
                                                    src={v.image}
                                                />
                                            </Link> :
                                            <img
                                                style={{
                                                    height: 300,
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                                src={v.image}
                                            />
                                    }


                                    <h4 className="py-3" style={{fontSize: "1.3em", fontWeight: 700}}>
                                        {
                                            v?.link ?
                                                <Link className={'product-title-link'} style={{ color: Palette.THEME_TEXT_DARK}}
                                                      to={v?.link}>{v?.title}</Link> :
                                                <> {v?.title}</>
                                        }
                                    </h4>

                                    <p
                                        style={{
                                            color: Palette.THEME_TEXT_MAIN,
                                            fontSize: isMobile ? '0.9em' : '1em'
                                        }}
                                    >
                                        {v.description}
                                    </p>

                                </Col>
                            </>
                        )
                    })}
                </Row>
            </Container>
        </>
    )
}
